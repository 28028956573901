const imageContainers = {
    desktop: [...document.querySelectorAll(".desktop .img-container")],
    tablet: [...document.querySelectorAll(".tablet .img-container")],
    mobile: [...document.querySelectorAll(".mobile .img-container")],
};

const desktopMedia = "screen and (min-width: 1600px)";
const tabletMedia = "screen and (min-width: 800px)";

function getCurrentImages() {
    if (window.matchMedia(desktopMedia).matches) {
        return imageContainers.desktop;
    } else if (window.matchMedia(tabletMedia).matches) {
        return imageContainers.tablet;
    }
    return imageContainers.mobile;
}
