class Scroller {
    constructor() {
        this.images = getCurrentImages();

        window.addEventListener('resize', () => {
            this.images = getCurrentImages();
        }, true);
    }

    scrollTo(index) {
        /**
         * @type {HTMLElement}
         */
        let target = this.images[0];

        if (index >= 0 && index < this.images.length) {
            target = this.images[index];
        }

        window.scrollTo({
            behavior: "smooth",
            top: target.offsetTop,
        });
    }

    findIndex(fromNode) {
        for (let index = 0; index < this.images.length; index++) {
            const image = this.images[index];
            if (image.contains(fromNode)) {
                return index;
            }
        }
    }

    scrollToNext(fromNode) {
        const currentIndex = this.findIndex(fromNode);
        this.scrollTo(currentIndex + 1);
    }

    toggleNext(fromNode) {
        const _this = this;
        const currentIndex = _this.findIndex(fromNode);
        const next = _this.images[currentIndex + 1];
        this.scrollTo(currentIndex + 1);
        if (next.classList.contains("is-closed")) {
            next.classList.remove("is-closed");
            setTimeout(() => {
                _this.showVideo(next);
            }, 350);
        } else {
            next.classList.add("is-closed");
            _this.hideVideo(next);
        }
    }

    slideUp(fromNode) {
        const _this = this;

        const currentIndex = _this.findIndex(fromNode);
        let slide = fromNode.closest('.img-container.collapsible');
        if (!slide.classList.contains("is-closed")) {
            slide.classList.add("is-closed");
            _this.hideVideo(slide);
        }
        this.scrollTo(currentIndex);
    }

    toggleNextPopUp(fromNode) {
        let next = getNextSibling(fromNode, '.pop-up');
        this.togglePopUp(next);
    }

    togglePopUp(popUp) {
        if (popUp.classList.contains("is-closed")) {
            popUp.classList.remove("is-closed");
        } else {
            popUp.classList.add("is-closed");
        }
    }

    close(fromNode) {
        const currentIndex = this.findIndex(fromNode);
        const img = this.images[currentIndex];
        this.scrollTo(currentIndex);
        img.classList.add('is-closed');
    }

    hideVideo(imageContainer) {
        var video = imageContainer.querySelector('.video');
        if (video) {
            if (video.classList.contains("is-hidden")) {
                return;
            }
            video.classList.add("is-hidden");
        }
    }

    showVideo(imageContainer) {
        var video = imageContainer.querySelector('.video');
        if (video) {
            if (video.classList.contains("is-hidden")) {
                video.classList.remove("is-hidden");
            }
        }
    }
}

/**
 * For browsers that do not support Element.matches() or Element.matchesSelector(),
 * but include support for document.querySelectorAll(), a polyfill exists
 * source: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {
            }
            return i > -1;
        };
}

function getNextSibling(elem, selector) {

    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling
    }

}